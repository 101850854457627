import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { PageHeader, notification } from "antd";

import { SingleCustomerForOrderQuery } from "../graphql/customers.graphql";
import { CreateQuotationMutation, QuotationsQuery } from "../graphql/quotations.graphql";

import { PageContainer } from "../components/atoms/PageContainer";
import { CustomerSelection } from "../components/CustomerSelection";
import { QuotationForm } from "../components/QuotationForm";
import { prepareQuotationFormValues } from ".././utils/prepareQuotationFormValues";

// import for achiving the function of UnsavedReminder
import UnsavedReminder from "../components/atoms/UnsavedReminder";
import { useCallbackPrompt } from "../hooks/useCallbackPrompt";
import { useNavigate } from "react-router-dom";

export const NewQuotation = () => {
  const [customer, setCustomer] = useState({});
  const navigate = useNavigate();
  // unsavedReminder
  const [showModal, setShowModal] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(true);
  const [isSaved, setIsSaved] = useState(false);

  const [fetchCustomer, { data }] = useLazyQuery(SingleCustomerForOrderQuery);
  const handleCustomerSelect = (_, option) => {
    fetchCustomer({ variables: { id: option.id } });
  };
  const [createQuotation] = useMutation(CreateQuotationMutation, {
    refetchQueries: [{ query: QuotationsQuery }],
  });

  useEffect(() => {
    if (isSaved) {
      setShowModal(false);
    } else setShowModal(showPrompt);
  }, [showPrompt, isSaved]);

  useEffect(() => {
    if (data) {
      setCustomer(data.customer);
    }
  }, [data]);

  const handleSave = async (values) => {
    setIsSaved(true);
    const data = prepareQuotationFormValues(values, customer, "create");
    const res = await createQuotation({ variables: { quotation: data } });
    if (res.data) {
      notification["success"]({
        message: `quote for ${res.data.createQuotation.customers[0].name} successfully created`,
      });

      navigate("/quotations");
      confirmNavigation();
    }
  };
  return (
    <>
      <PageHeader title={`New quote . ${customer?.name} `} />
      <UnsavedReminder
        showModal={showModal}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <PageContainer>
        {customer.id ? (
          <QuotationForm customer={customer} setCustomer={setCustomer} onSave={handleSave} />
        ) : (
          <CustomerSelection onCustomerSelect={handleCustomerSelect} />
        )}
      </PageContainer>
    </>
  );
};
