import { Link } from "react-router-dom";
import { PageHeader, Button, Table, Dropdown, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import { QuotationsQuery } from "../graphql/quotations.graphql";

import { ROUTES } from "../constants";
import { PageContainer } from "../components/atoms/PageContainer";
import { PageContentContainer } from "../components/atoms/PageContentContainer";
import { ResourceToolbar } from "../components/ResourceToolbar";
import { QuotationsActionsMenu } from "../components/tablecells/QuotationsActionsCell";
import { StatusTag } from "../components/tags/StatusTag";

const columns = [
  {
    title: "#",
    dataIndex: "name",
    key: "name",
    render: (name, record) => {
      return (
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          <Link to={`/quotations/${record.id}`}>{name}</Link>
        </Typography.Title>
      );
    },
  },
  {
    title: "Customer",
    dataIndex: "customer",
    key: "customer",
    render: (_, record) => {
      return (
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          {record.customers[0].name}
        </Typography.Title>
      );
    },
  },
  {
    title: "Quote Start",
    dataIndex: "start",
    key: "start",
    render: (start) => moment(start).format("DD/MM/YYYY"),
  },
  {
    title: "Quote End",
    dataIndex: "end",
    key: "end",
    render: (end) => moment(end).format("DD/MM/YYYY"),
  },
  {
    title: "No. of items",
    dataIndex: "lineItemsCount",
    key: "lineItemsCount",
    render: (count) => `${count} items`,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text) => <StatusTag status={text} />,
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    render: (_, record) => {
      return (
        <Dropdown.Button trigger={["click"]} overlay={<QuotationsActionsMenu quotation={record} />}>
          <Link to={`/quotations/${record.id}/edit`}>
            Edit <EditOutlined />
          </Link>
        </Dropdown.Button>
      );
    },
  },
];

const pageSize = 20;
const today = moment().format("DD/MM/YYYY");

export const QuotationsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [quotations, setQuotations] = useState([]);
  const [dateRange, setDateRange] = useState(`${today} -`);
  const [filters, setFilters] = useState({
    isCancelled: { equals: false },
    // start: { gte: moment().startOf("day").toISOString() },
    // end: { lte: moment().endOf("day").toISOString() },
  });
  const { data, loading, refetch, networkStatus } = useQuery(QuotationsQuery, {
    variables: { take: pageSize, skip: (currentPage - 1) * pageSize, where: filters },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data?.quotations) {
      setQuotations(data.quotations);
    }
  }, [data, loading]);

  const handleSearch = (value) => {
    const customerfilter = { name: { contains: value, mode: "insensitive" } };
    setFilters((f) => ({
      ...f,
      OR: [{ name: { contains: value, mode: "insensitive" } }, { customer: customerfilter }],
    }));
  };
  const handleRefresh = () => {
    refetch();
  };
  const handleRangeChange = (range) => {
    if (!range) {
      const fil = { ...filters };
      delete fil.start;
      delete fil.end;
      setFilters(fil);
      setDateRange("All");
      console.log("DateRange: ", dateRange);
      return;
    }

    const [startDate, endDate] = range;

    if (!startDate) {
      setDateRange(`- ${endDate.format("DD/MM/YYYY")}`);
      setFilters((f) => ({
        ...f,
        end: {
          lte: endDate.endOf("day").toISOString(),
        },
      }));
      return;
    }
    if (!endDate) {
      setDateRange(`${startDate.format("DD/MM/YYYY")} -`);
      setFilters((f) => ({
        ...f,
        start: {
          gte: startDate.startOf("day").toISOString(),
        },
      }));
      return;
    }

    setDateRange(`${startDate.format("DD/MM/YYYY")} - ${endDate.format("DD/MM/YYYY")}`);
    setFilters((f) => ({
      ...f,
      start: { gte: startDate.startOf("day").toISOString() },
      end: { lte: endDate.endOf("day").toISOString() },
    }));
  };

  return (
    <PageContainer>
      <PageHeader
        ghost={true}
        title={moment().format("YYYY-MM-DD,dddd")}
        subTitle={`Showing ${quotations.length} quotations of ${data?.quotationsCount} `}
        extra={[
          <Link key="new-quotations" to={ROUTES.quotationsNew}>
            <Button type="primary">New Quote</Button>
          </Link>,
        ]}
      />
      <PageContentContainer>
        <ResourceToolbar
          search={{ onSearch: handleSearch }}
          dateRange={{ onRangeChange: handleRangeChange }}
          onRefresh={handleRefresh}
        />
        <Table
          columns={columns}
          dataSource={quotations}
          rowKey="id"
          size="medium"
          loading={loading || networkStatus}
          pagination={{
            total: data?.quotationsCount,
            pageSize: pageSize,
            showSizeChanger: false,
            onChange: (currentPage) => {
              setCurrentPage(currentPage);
            },
          }}
        />
      </PageContentContainer>
    </PageContainer>
  );
};
