import { Page, View, Document, Font } from "@react-pdf/renderer";
import { HorizontalLine, Typography, PageNumber, Row, Stack } from "./utilities";
import { VendorHeader, VendorHeaderEverfresh, VendorHeaderGlory } from "./VendorHeader";
import { BankDetailsEverfresh, BankDetailsGlory, Bankdetails } from "./Bankdetails";
import { formatMoney } from "@/utils";
Font.register({
  family: "Microsoft YaHei",
  src: "/fonts/chinese.msyh.ttf",
});

const getBalanceDue = (order) => {
  let balanceDue = order?.balanceDue;
  if (order.payments.length === 0) balanceDue = order.total;
  return balanceDue;
};

export const InvoicePDF = ({ order, orderDate, language }) => {
  if (order?.id === undefined) return null;

  const balanceDue = getBalanceDue(order);

  const tableColumns = [
    {
      title: "#",
      dataKey: "index",
      key: "index",
      span: 0.5,
      align: "left",
      render: (_, record, index) => {
        return record && `${index + 1}`;
      },
    },
    {
      title: "Description",
      dataKey: "name",
      key: "nameAndNotes",
      span: 4.75,
      align: "left",
      render: (_, record) => {
        let name = language === "single" ? record.product?.name_EN : record.product?.name;
        let notes = record.notes || "";

        // notes for na and replace items
        if (record.pickingStatus === "na") notes = "This item is not available at this moment";
        if (record.pickingStatus === "replace") notes = "This is a replace item with no charge";
        return { name, notes };
      },
    },
    {
      title: "Unit",
      dataKey: "packagingUnit",
      key: "packagingUnit",
      span: 2,
      align: "left",
      render: (_, record) => {
        // if (record.isOpenItem) return record.packagingUnit ?? "";
        return record.productVariant?.name || "";
      },
    },

    {
      title: "Quantity",
      dataKey: "quantity",
      key: "quantity",
      span: 1,
      align: "left",
    },
    {
      title: "Unit Price",
      dataKey: "unitPrice",
      key: "unitPrice",
      span: 1.75,
      align: "right",
      render: (value) => `$${value}`,
    },
    {
      title: "Line Total",
      dataKey: "lineTotal",
      key: "lineTotal",
      span: 2,
      align: "right",
      render: (_, record) => formatMoney(record.unitPrice * record.quantity),
    },
  ];

  if (order?.customer?.invoiceTemplate === "EVER_FRESH") {
    return (
      <Document>
        <Page
          size="A4"
          style={{
            paddingTop: 32,
            paddingBottom: 40,
            paddingHorizontal: 32,
            backgroundColor: "#fff",
          }}
        >
          <Row>
            <Stack
              sx={{
                width: "50%",
                alignItems: "flex-start",
              }}
            >
              <Typography type="header" sx={{ fontSize: "24px", textAlign: "right" }}>
                {`TAX INVOICE${order?.status === " draft" ? "(Draft)" : ""}`}
              </Typography>
              <Typography sx={{ textAlign: "left" }}>INVOICE NO: {`#${order?.name}`}</Typography>
              <Typography sx={{ textAlign: "left" }}>ABN: {`29630254858`}</Typography>
              <Typography sx={{ textAlign: "left" }}>DELIVERY DATE: {orderDate}</Typography>
              <Typography sx={{ textAlign: "left" }}>DUE DATE: {orderDate}</Typography>
            </Stack>
            <VendorHeaderEverfresh />
          </Row>

          <Row sx={{ marginTop: 24, marginBottom: 24 }}>
            <Stack
              sx={{
                width: "65%",
                marginRight: "8px",
              }}
            >
              <Typography type="header" sx={{ fontFamily: "Microsoft YaHei" }}>
                {order.customer?.name}
              </Typography>
              <Typography>(Add) {order.deliveryAddress}</Typography>
              <Typography>(Phone) </Typography>
              <Typography type="header">{order.deliveryNotes}</Typography>
            </Stack>
          </Row>

          {/* table header  */}

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              columnGap: "4px",
            }}
          >
            {tableColumns.map((th) => {
              const columnWidth = `${(th.span / 12) * 100}%`;
              return (
                <Typography
                  key={th.key}
                  sx={{
                    fontWeight: "bold",
                    width: columnWidth,
                    textAlign: th.align,
                    margin: "0",
                  }}
                >
                  {th.title}
                </Typography>
              );
            })}
          </View>

          <HorizontalLine sx={{ borderBottom: "2px solid lightgrey", margin: "2px 0 2px 0" }} />

          {/* table body  */}

          {order?.lineItems &&
            order.lineItems.map((item, index) => {
              return (
                <View
                  key={item.id}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    columnGap: "4px",
                    padding: "4px 0",
                    borderBottom: "0.35px solid lightgrey",
                  }}
                >
                  {tableColumns.map((th) => {
                    const columnWidth = `${(th.span / 12) * 100}%`;
                    const value = item[th.dataKey];
                    const isStrikeThrough =
                      (th.dataKey === "unitPrice" || th.dataKey === "lineTotal") &&
                      (item.pickingStatus === "na" || item.pickingStatus === "replace");
                    const renderContent = th.render ? th.render(value, item, index) : value;
                    if (th.dataKey === "name") {
                      const { name, notes } = renderContent;
                      return (
                        <View
                          key={th.key}
                          style={{
                            width: columnWidth,
                            textAlign: th.align,
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Microsoft YaHei",
                              textDectoration: isStrikeThrough ? "line-through" : "none",
                            }}
                          >
                            {name}
                          </Typography>
                          {notes.length ? (
                            <Typography
                              sx={{
                                fontFamily: "Microsoft YaHei",
                                color: "grey",
                              }}
                            >
                              Comments: {notes}
                            </Typography>
                          ) : null}
                        </View>
                      );
                    } else {
                      return (
                        <View
                          key={th.key}
                          style={{
                            width: columnWidth,
                            textAlign: th.align,
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Microsoft YaHei",
                              textDectoration: isStrikeThrough ? "line-through" : "none",
                            }}
                          >
                            {renderContent}
                          </Typography>
                        </View>
                      );
                    }
                  })}
                </View>
              );
            })}
          <HorizontalLine sx={{ borderBottom: "2px solid lightgrey", margin: "2px 0 2px 0" }} />

          <Row
            sx={{
              justifyContent: "space-between",
            }}
          >
            {/* payment terms and bank details */}
            <View style={{ marginTop: 8 }}>
              <BankDetailsEverfresh />
            </View>
            {/* payment terms and bank details */}

            {/* total numbers */}
            <Stack sx={{ padding: "0 4px" }}>
              <Row
                sx={{
                  padding: "3px 0",
                  justifyContent: "flex-end",
                }}
              >
                <Typography sx={{ marginRight: "4px" }}>SUBTOTAL</Typography>
                <Typography sx={{ width: 96, textAlign: "right" }}>
                  {formatMoney(order?.totalWithoutGst)}
                </Typography>
              </Row>
              <Row
                sx={{
                  padding: "3px 0",
                  justifyContent: "flex-end",
                }}
              >
                <Typography sx={{ marginRight: "4px" }}>GST</Typography>
                <Typography sx={{ width: 96, textAlign: "right" }}>$0.00</Typography>
              </Row>
              <Row
                sx={{
                  padding: "3px 0",
                  justifyContent: "flex-end",
                }}
              >
                <Typography sx={{ marginRight: "4px" }}>Discount</Typography>
                <Typography sx={{ width: 96, textAlign: "right" }}>
                  {formatMoney(order?.discountAmount)}
                </Typography>
              </Row>
              <Row
                sx={{
                  padding: "5px 0",
                  justifyContent: "flex-end",
                }}
              >
                <Typography type="header" sx={{ fontSize: 12, marginRight: "4px" }}>
                  Total
                </Typography>
                <Typography type="header" sx={{ fontSize: 14, width: 96, textAlign: "right" }}>
                  {formatMoney(order?.total)}
                </Typography>
              </Row>
              {order?.payments.map((payment) => {
                return (
                  <Row
                    sx={{
                      padding: "3px 0",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography sx={{ marginRight: "4px" }}>{payment.name}</Typography>
                    <Typography sx={{ width: 96, textAlign: "right" }}>
                      {formatMoney(payment.amount)}
                    </Typography>
                  </Row>
                );
              })}

              <HorizontalLine sx={{ borderBottom: "2px solid lightgrey", margin: "2px 0 2px 0" }} />

              <Row sx={{ justifyContent: "flex-end" }}>
                <Typography type="header" sx={{ fontSize: 12, marginRight: "4px" }}>
                  AMOUT DUE
                </Typography>
                <Typography type="header" sx={{ fontSize: 14, width: 96, textAlign: "right" }}>
                  {formatMoney(balanceDue)}
                </Typography>
              </Row>
            </Stack>
            {/* total numbers */}
          </Row>

          <View>
            <Typography type="header">Ever Fresh Group</Typography>
            <Typography>(Add) 21 Park Road, Homebush West NSW 2140</Typography>
            <Typography>(Email) admin@everfreshgroup.com.au</Typography>
            <Typography>(ABN) 29630254858</Typography>
          </View>

          <PageNumber />
        </Page>
      </Document>
    );
  }

  if (order?.customer?.invoiceTemplate === "GLORY_FRESH") {
    return (
      <Document>
        <Page
          size="A4"
          style={{
            paddingTop: 32,
            paddingBottom: 40,
            paddingHorizontal: 32,
            backgroundColor: "#fff",
          }}
        >
          <Row>
            <Stack
              sx={{
                width: "50%",
                alignItems: "flex-start",
              }}
            >
              <Typography type="header" sx={{ fontSize: "24px", textAlign: "right" }}>
                {`TAX INVOICE${order?.status === " draft" ? "(Draft)" : ""}`}
              </Typography>
              <Typography sx={{ textAlign: "left" }}>INVOICE NO: {`#${order?.name}`}</Typography>
              <Typography sx={{ textAlign: "left" }}>ABN: {`13663504434`}</Typography>
              <Typography sx={{ textAlign: "left" }}>DELIVERY DATE: {orderDate}</Typography>
              <Typography sx={{ textAlign: "left" }}>DUE DATE: {orderDate}</Typography>
            </Stack>
            <VendorHeaderGlory />
          </Row>

          <Row sx={{ marginTop: 24, marginBottom: 24 }}>
            <Stack
              sx={{
                width: "65%",
                marginRight: "8px",
              }}
            >
              <Typography type="header" sx={{ fontFamily: "Microsoft YaHei" }}>
                {order.customer?.name}
              </Typography>
              <Typography>(Add) {order.deliveryAddress}</Typography>
              <Typography>(Phone) </Typography>
              <Typography type="header">{order.notes}</Typography>
            </Stack>
          </Row>

          {/* table header  */}

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              columnGap: "4px",
            }}
          >
            {tableColumns.map((th) => {
              const columnWidth = `${(th.span / 12) * 100}%`;
              return (
                <Typography
                  key={th.key}
                  sx={{
                    fontWeight: "bold",
                    width: columnWidth,
                    textAlign: th.align,
                    margin: "0",
                  }}
                >
                  {th.title}
                </Typography>
              );
            })}
          </View>

          <HorizontalLine sx={{ borderBottom: "2px solid lightgrey", margin: "2px 0 2px 0" }} />

          {/* table body  */}

          {order?.lineItems &&
            order.lineItems.map((item, index) => {
              return (
                <View
                  key={item.id}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    columnGap: "4px",
                    padding: "4px 0",
                    borderBottom: "0.35px solid lightgrey",
                  }}
                >
                  {tableColumns.map((th) => {
                    const columnWidth = `${(th.span / 12) * 100}%`;
                    const value = item[th.dataKey];
                    const isStrikeThrough =
                      (th.dataKey === "unitPrice" || th.dataKey === "lineTotal") &&
                      (item.pickingStatus === "na" || item.pickingStatus === "replace");
                    const renderContent = th.render ? th.render(value, item, index) : value;
                    if (th.dataKey === "name") {
                      const { name, notes } = renderContent;
                      return (
                        <View
                          key={th.key}
                          style={{
                            width: columnWidth,
                            textAlign: th.align,
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Microsoft YaHei",
                              textDectoration: isStrikeThrough ? "line-through" : "none",
                            }}
                          >
                            {name}
                          </Typography>
                          {notes.length ? (
                            <Typography
                              sx={{
                                fontFamily: "Microsoft YaHei",
                                color: "grey",
                              }}
                            >
                              Comments: {notes}
                            </Typography>
                          ) : null}
                        </View>
                      );
                    } else {
                      return (
                        <View
                          key={th.key}
                          style={{
                            width: columnWidth,
                            textAlign: th.align,
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Microsoft YaHei",
                              textDectoration: isStrikeThrough ? "line-through" : "none",
                            }}
                          >
                            {renderContent}
                          </Typography>
                        </View>
                      );
                    }
                  })}
                </View>
              );
            })}
          <HorizontalLine sx={{ borderBottom: "2px solid lightgrey", margin: "2px 0 2px 0" }} />

          <Row
            sx={{
              justifyContent: "space-between",
            }}
          >
            {/* payment terms and bank details */}
            <View style={{ marginTop: 8 }}>
              <BankDetailsGlory />
            </View>
            {/* payment terms and bank details */}

            {/* total numbers */}
            <Stack sx={{ padding: "0 4px" }}>
              <Row
                sx={{
                  padding: "3px 0",
                  justifyContent: "flex-end",
                }}
              >
                <Typography sx={{ marginRight: "4px" }}>SUBTOTAL</Typography>
                <Typography sx={{ width: 96, textAlign: "right" }}>
                  {formatMoney(order?.totalWithoutGst)}
                </Typography>
              </Row>
              <Row
                sx={{
                  padding: "3px 0",
                  justifyContent: "flex-end",
                }}
              >
                <Typography sx={{ marginRight: "4px" }}>GST</Typography>
                <Typography sx={{ width: 96, textAlign: "right" }}>$0.00</Typography>
              </Row>
              <Row
                sx={{
                  padding: "3px 0",
                  justifyContent: "flex-end",
                }}
              >
                <Typography sx={{ marginRight: "4px" }}>Discount</Typography>
                <Typography sx={{ width: 96, textAlign: "right" }}>
                  {formatMoney(order?.discountAmount)}
                </Typography>
              </Row>
              <Row
                sx={{
                  padding: "5px 0",
                  justifyContent: "flex-end",
                }}
              >
                <Typography type="header" sx={{ fontSize: 12, marginRight: "4px" }}>
                  Total
                </Typography>
                <Typography type="header" sx={{ fontSize: 14, width: 96, textAlign: "right" }}>
                  {formatMoney(order?.total)}
                </Typography>
              </Row>
              {order?.payments.map((payment) => {
                return (
                  <Row
                    sx={{
                      padding: "3px 0",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography sx={{ marginRight: "4px" }}>{payment.name}</Typography>
                    <Typography sx={{ width: 96, textAlign: "right" }}>
                      {formatMoney(payment.amount)}
                    </Typography>
                  </Row>
                );
              })}

              <HorizontalLine sx={{ borderBottom: "2px solid lightgrey", margin: "2px 0 2px 0" }} />

              <Row sx={{ justifyContent: "flex-end" }}>
                <Typography type="header" sx={{ fontSize: 12, marginRight: "4px" }}>
                  AMOUT DUE
                </Typography>
                <Typography type="header" sx={{ fontSize: 14, width: 96, textAlign: "right" }}>
                  {formatMoney(balanceDue)}
                </Typography>
              </Row>
            </Stack>
            {/* total numbers */}
          </Row>

          <View>
            <Typography type="header">Glory Fresh Produce</Typography>
            <Typography>(Add) 1 Schofield St, Riverwood NSW 2210</Typography>
            <Typography>(Email) gloryfresh.office@gmail.com</Typography>
            <Typography>(Instagram) gloryfreshproduce</Typography>
            <Typography>(Phone) 0400 628 618</Typography>
            <Typography>(ABN) 13663504434</Typography>
          </View>

          <PageNumber />
        </Page>
      </Document>
    );
  }

  return (
    <Document>
      <Page
        size="A4"
        style={{
          paddingTop: 32,
          paddingBottom: 40,
          paddingHorizontal: 32,
          backgroundColor: "#fff",
        }}
      >
        <Row>
          <VendorHeader />
          <Stack
            sx={{
              width: "50%",
              alignItems: "flex-end",
            }}
          >
            <Typography type="header" sx={{ fontSize: "24px", textAlign: "right" }}>
              {order?.status === "draft" ? `DRAFT #${order?.name}` : `INVOICE #${order?.name}`}
            </Typography>
            <Typography sx={{ textAlign: "right" }}>Invoice date: {orderDate}</Typography>
            <Typography sx={{ textAlign: "right" }}>Due date: {orderDate}</Typography>
          </Stack>
        </Row>

        <Row sx={{ marginTop: 24, marginBottom: 24 }}>
          <Stack
            sx={{
              width: "50%",
              borderRadius: "5px",
              border: "1px solid #000",
              padding: "8px",
              marginRight: "8px",
            }}
          >
            <Typography>To customer: </Typography>
            <Typography type="header" sx={{ fontFamily: "Microsoft YaHei" }}>
              {order.customer?.name}
            </Typography>
            <Typography>Billing Add: {order.customer?.billingAddress}</Typography>
            <Typography>Email: {/*order.customer?.email*/}</Typography>
            <Typography>Phone: {/*order.customer?.phone*/}</Typography>
          </Stack>

          <Stack
            sx={{
              borderRadius: "5px",
              border: "1px solid #000",
              width: "50%",
              padding: "8px",
            }}
          >
            <Typography>Delivery Address</Typography>
            <Typography sx={{ fontSize: 12 }}>{order.deliveryAddress}</Typography>
            <Typography sx={{ marginTop: 4 }}>Note</Typography>
            <Typography type="header" sx={{ fontSize: 14 }}>
              {order.notes}
            </Typography>
          </Stack>
        </Row>

        {/* table header  */}

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            columnGap: "4px",
          }}
        >
          {tableColumns.map((th) => {
            const columnWidth = `${(th.span / 12) * 100}%`;
            return (
              <Typography
                key={th.key}
                sx={{
                  fontWeight: "bold",
                  width: columnWidth,
                  textAlign: th.align,
                }}
              >
                {th.title}
              </Typography>
            );
          })}
        </View>
        <HorizontalLine />

        {/* table body  */}

        {order?.lineItems &&
          order.lineItems.map((item, index) => {
            return (
              <View
                key={item.id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  columnGap: "4px",
                  padding: "4px 0",
                  borderBottom: "0.35px solid lightgrey",
                }}
              >
                {tableColumns.map((th) => {
                  const columnWidth = `${(th.span / 12) * 100}%`;
                  const value = item[th.dataKey];
                  const isStrikeThrough =
                    (th.dataKey === "unitPrice" || th.dataKey === "lineTotal") &&
                    (item.pickingStatus === "na" || item.pickingStatus === "replace");
                  const renderContent = th.render ? th.render(value, item, index) : value;
                  if (th.dataKey === "name") {
                    const { name, notes } = renderContent;
                    return (
                      <View
                        key={th.key}
                        style={{
                          width: columnWidth,
                          textAlign: th.align,
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Microsoft YaHei",
                            textDectoration: isStrikeThrough ? "line-through" : "none",
                          }}
                        >
                          {name}
                        </Typography>
                        {notes.length ? (
                          <Typography
                            sx={{
                              fontFamily: "Microsoft YaHei",
                              color: "grey",
                            }}
                          >
                            Comments: {notes}
                          </Typography>
                        ) : null}
                      </View>
                    );
                  } else {
                    return (
                      <View
                        key={th.key}
                        style={{
                          width: columnWidth,
                          textAlign: th.align,
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Microsoft YaHei",
                            textDectoration: isStrikeThrough ? "line-through" : "none",
                          }}
                        >
                          {renderContent}
                        </Typography>
                      </View>
                    );
                  }
                })}
              </View>
            );
          })}

        <HorizontalLine />

        <Row
          sx={{
            justifyContent: "space-between",
          }}
        >
          {/* payment terms and bank details */}
          <Stack>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "5px 0",
              }}
            >
              <Bankdetails
                total={order?.total}
                balanceDue={balanceDue}
                balance={order?.customer?.accountBalance}
              />
            </View>
          </Stack>
          {/* payment terms and bank details */}

          {/* total numbers */}
          <Stack sx={{ padding: "0 4px" }}>
            <Row
              sx={{
                padding: "3px 0",
                justifyContent: "flex-end",
              }}
            >
              <Typography sx={{ marginRight: "4px" }}>Subtotal</Typography>
              <Typography sx={{ width: 96, textAlign: "right" }}>
                {formatMoney(order?.totalWithoutGst)}
              </Typography>
            </Row>
            <Row
              sx={{
                padding: "3px 0",
                justifyContent: "flex-end",
              }}
            >
              <Typography sx={{ marginRight: "4px" }}>GST</Typography>
              <Typography sx={{ width: 96, textAlign: "right" }}>$0.00</Typography>
            </Row>
            <Row
              sx={{
                padding: "3px 0",
                justifyContent: "flex-end",
              }}
            >
              <Typography sx={{ marginRight: "4px" }}>Discount</Typography>
              <Typography sx={{ width: 96, textAlign: "right" }}>
                {formatMoney(order?.discountAmount)}
              </Typography>
            </Row>
            <Row
              sx={{
                padding: "5px 0",
                justifyContent: "flex-end",
              }}
            >
              <Typography type="header" sx={{ fontSize: 16, marginRight: "4px" }}>
                Invoice total
              </Typography>
              <Typography type="header" sx={{ fontSize: 16, width: 96, textAlign: "right" }}>
                {formatMoney(order?.total)}
              </Typography>
            </Row>
            {order?.payments.map((payment) => {
              return (
                <Row
                  sx={{
                    padding: "3px 0",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography sx={{ marginRight: "4px" }}>{payment.name}</Typography>
                  <Typography sx={{ width: 96, textAlign: "right" }}>
                    {formatMoney(payment.amount)}
                  </Typography>
                </Row>
              );
            })}
            <Row sx={{ justifyContent: "flex-end" }}>
              <Typography type="header" sx={{ fontSize: 16, marginRight: "4px" }}>
                Balance Due
              </Typography>
              <Typography type="header" sx={{ fontSize: 16, width: 96, textAlign: "right" }}>
                {formatMoney(balanceDue)}
              </Typography>
            </Row>
          </Stack>
          {/* total numbers */}
        </Row>

        <PageNumber />
      </Page>
    </Document>
  );
};
