import { Menu } from "antd";
import { useMutation } from "@apollo/client";

import { QuotationsQuery, SoftDeleteQuotationMutation } from "../../../graphql/quotations.graphql";

export const QuotationsActionsMenu = ({ quotation }) => {
  const [softDeleteQuotation] = useMutation(SoftDeleteQuotationMutation, {
    refetchQueries: [
      {
        query: QuotationsQuery,
      },
    ],
  });
  const handleMenuClick = ({ key }) => {
    if (key === "delete") {
      const res = softDeleteQuotation({ variables: { id: quotation.id } });
      console.log("quotation cancelled: ", res);
    }
    // const res = softDeleteQuotation({ variables: { id: quotation.id } });
    // console.log("quotation cancelled: ", res);
  };

  return (
    <>
      <Menu onClick={handleMenuClick}>
        {/* <Menu.Divider /> */}
        <Menu.Item key="delete" danger>
          Delete
        </Menu.Item>
      </Menu>
    </>
  );
};
