import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import moment from "moment";

import {
  Space,
  Button,
  Row,
  Col,
  Card,
  Typography,
  Divider,
  PageHeader,
  Dropdown,
  Menu,
} from "antd";
import { usePDF } from "@react-pdf/renderer";

import { useParams } from "react-router-dom";
import { SingleOrderAsCreditNoteQuery } from "../graphql/orders.graphql";

import { OrderTotalSummary } from "../components/OrderTotalSummary";
import { PageContainer } from "../components/atoms/PageContainer";
import { StatusTag } from "../components/tags/StatusTag";
import { EditFilled, PrinterOutlined, FilePdfOutlined, SyncOutlined } from "@ant-design/icons";
import { CreditNoteInfoCard } from "../components/CreditNoteInforCard";
import { CnDetailItemsTable } from "../components/CnDetailItemsTable";
import { CreditNotePDF } from "../components/pdf/CreditNotePDF";

const itemPaddingLeft = { paddingLeft: "18px" };

export const CreditNoteDetail = () => {
  const params = useParams();
  const [order, setOrder] = useState({});
  const [items, setItems] = useState([]);
  const [orderDate, setOrderDate] = useState("");
  const [customer, setCustomer] = useState({ name: "" });
  const { data, loading, refetch } = useQuery(SingleOrderAsCreditNoteQuery, {
    variables: { id: params.id },
    fetchPolicy: "cache-and-network",
  });

  const [invoiceEnglish, updateInvoiceSingle] = usePDF({
    document: <CreditNotePDF order={order} orderDate={orderDate} language="single" />,
  });

  useEffect(() => {
    if (data?.order) {
      setOrder(data.order);
      setItems(data.order.lineItems);
      setCustomer(data.order.customer);
      setOrderDate(moment(data.order.orderDate).format("DD/MM/YYYY"));
    }
  }, [data]);

  useEffect(() => {
    if (order.id && !invoiceEnglish.url) {
      updateInvoiceSingle();
    }
  }, [order, invoiceEnglish.url, updateInvoiceSingle]);

  const printPDF = (
    <Menu
      items={[
        {
          key: "2",
          icon: <PrinterOutlined />,
          style: itemPaddingLeft,
          label: (
            <a target="_blank" rel="noreferrer" href={invoiceEnglish.url}>
              English
            </a>
          ),
        },
      ]}
    />
  );

  const downloadPDF = (
    <Menu
      items={[
        {
          key: "4",
          icon: <FilePdfOutlined />,
          style: itemPaddingLeft,
          label: (
            <a
              target="_blank"
              rel="noreferrer"
              href={invoiceEnglish.url}
              download={`${customer.name}_Creidt_Note_#${order.name}_${moment(
                order.orderDate
              ).format("DD/MM/YYYY")}.pdf`}
            >
              English
            </a>
          ),
        },
      ]}
    />
  );

  return (
    <>
      <PageHeader
        title={
          <Space>
            <Typography.Text># {order.name}</Typography.Text>
            <Typography.Title level={3} style={{ marginBottom: 0 }}>
              {customer.name}
            </Typography.Title>
            <Space>
              <StatusTag status={order.status} />
              <StatusTag status={order.paymentStatus} />
              <StatusTag status={order.invoiceStatus} />
            </Space>
          </Space>
        }
        extra={[
          <Link key="edit-order" to={`/credit-notes/${order.id}/edit`}>
            <Button type="primary" loading={loading} icon={<EditFilled />}>
              Edit
            </Button>
          </Link>,
          <Dropdown key="print-order" overlay={printPDF} placement="bottom">
            <Button loading={invoiceEnglish.loading} icon={<PrinterOutlined />}>
              Print
            </Button>
          </Dropdown>,
          <Dropdown key="download-order" overlay={downloadPDF} placement="bottom">
            <Button loading={invoiceEnglish.loading} icon={<FilePdfOutlined />}>
              Download
            </Button>
          </Dropdown>,
          <Button
            key="refresh"
            onClick={() => refetch()}
            loading={loading}
            icon={<SyncOutlined />}
          />,
        ]}
      />
      <PageContainer>
        <Row gutter={16}>
          <Col span={16}>
            <Card style={{ width: "100%" }}>
              <CnDetailItemsTable items={items} loading={loading} />
              <Divider />
              <OrderTotalSummary
                total={order.total}
                totalWithoutGst={order.totalWithoutGst}
                gstAmount={order.gstAmount}
                discountAmount={order.discountAmount}
              />
            </Card>
          </Col>
          <Col span={8}>
            <CreditNoteInfoCard customer={customer} order={order} />
          </Col>
        </Row>
      </PageContainer>
    </>
  );
};
