import { getOrderTotal } from "./orderCalculations";

const FULFILLMENT_STATUS = {
  missing: "missing",
  unpacked: "unpacked",
  packed: "packed",
  packing: "packing",
};

const getFulfillmentStatus = ({ missing, pack }, len) => {
  if (len === 0) return FULFILLMENT_STATUS.packed;

  // #1. untouched, all items are pack
  if (pack === len) return FULFILLMENT_STATUS.unpacked;

  // #2. none of them is pack or missing
  if (pack === 0 && missing === 0) return FULFILLMENT_STATUS.packed;

  return FULFILLMENT_STATUS.missing;
};

export const sanitizeOrderFormValues = ({ values, operation = "create", user }) => {
  const basicInfo = values.basicInfo;
  const unsanitizedLineItems = values.lineItems.filter((item) => item?.name !== undefined);
  const lineItems = unsanitizedLineItems.reduce(
    (total, item, index) => {
      const lineItemBase = {
        id: item.id,
        name: item.name || item.product.name,
        quantity: String(item.quantity),
        unitPrice: String(item.unitPrice),
        unitCost: String(item.unitCost || item.unitPrice),
        sortingIndex: index,
        pickingStatus: item.pickingStatus,
        isOpenItem: item.isOpenItem,
        notes: item.notes,
      };

      if (item.isOpenItem) {
        total.lineItems.push(lineItemBase);
      } else {
        total.lineItems.push({
          ...lineItemBase,
          productVariant: { connect: { id: item.productVariantId } },
          product: { connect: { id: item.product.id } },
        });
      }

      const currentStatusCount = total.pickingStatusGroup[item.pickingStatus];

      total.pickingStatusGroup = {
        ...total.pickingStatusGroup,
        [item.pickingStatus]: currentStatusCount + 1,
      };

      return total;
    },
    { lineItems: [], pickingStatusGroup: { done: 0, missing: 0, pack: 0, replace: 0, na: 0 } }
  );

  const fulfillmentStatus = getFulfillmentStatus(
    lineItems.pickingStatusGroup,
    lineItems.lineItems.length
  );

  const total = getOrderTotal(lineItems.lineItems);

  const datetime = values.basicInfo.orderDate.hour(9).minute(0);
  const data = {
    ...total,
    lineItems: operation === "create" ? { create: lineItems.lineItems } : lineItems.lineItems,
    ...basicInfo,
    orderDate: datetime,
    fulfillmentStatus,
  };

  if (operation === "create") {
    data.organisation = { connect: { id: user.organisations[0].id } };
  }
  return data;
};

export const prepareOrderPayloadForRestApi = (values, operation = "create") => {
  const basicInfo = values.basicInfo;
  const unsanitizedLineItems = values.lineItems.filter((item) => item?.name !== undefined);
  const lineItems = unsanitizedLineItems.reduce(
    (total, item, index) => {
      const lineItemBase = {
        id: item.id,
        name: item.name || item.product.name,
        quantity: String(item.quantity),
        unitPrice: String(item.unitPrice),
        unitCost: String(item.unitCost || item.unitPrice),
        sortingIndex: index,
        pickingStatus: item.pickingStatus,
        isOpenItem: item.isOpenItem,
        notes: item.notes,
      };

      if (item.isOpenItem) {
        total.lineItems.push(lineItemBase);
      } else {
        total.lineItems.push({
          ...lineItemBase,
          productVariant: { connect: { id: item.productVariantId } },
          product: { connect: { id: item.product.id } },
        });
      }

      const currentStatusCount = total.pickingStatusGroup[item.pickingStatus];

      total.pickingStatusGroup = {
        ...total.pickingStatusGroup,
        [item.pickingStatus]: currentStatusCount + 1,
      };

      return total;
    },
    { lineItems: [], pickingStatusGroup: { done: 0, missing: 0, pack: 0, replace: 0, na: 0 } }
  );

  const fulfillmentStatus = getFulfillmentStatus(
    lineItems.pickingStatusGroup,
    lineItems.lineItems.length
  );

  const total = getOrderTotal(lineItems.lineItems);

  const orderDate = values.basicInfo.orderDate.hour(9).minute(0);
  const data = {
    ...total,
    lineItems: lineItems.lineItems,
    ...basicInfo,
    orderDate: orderDate,
    fulfillmentStatus,
  };
  return data;
};
