import { Table } from "antd";
import { MoneyCell } from "../tablecells";
import { formatMoney } from "../../utils";
import { StatusTag } from "../tags/StatusTag";

export const LineItemsColumns = [
  {
    title: "#",
    dataIndex: "sortingIndex",
    key: "sortingIndex",
    render: (text) => Number(text) + 1,
  },
  {
    title: "Description",
    dataIndex: "name",
    key: "name",
  },
  { title: "Comments", dataIndex: "notes", key: "notes" },
  {
    title: "Unit",
    dataIndex: "unit",
    key: "unit",
    render: (_, record) => {
      if (record.isOpenItem) return record.packagingUnit ?? "";
      return record.productVariant && record.productVariant.name;
    },
  },

  {
    title: "QTY",
    dataIndex: "quantity",
    key: "qty",
    align: "right",
  },
  {
    title: "Price",
    dataIndex: "unitPrice",
    key: "price",
    ...MoneyCell,
  },
  {
    title: "Line Total",
    dataIndex: "amount",
    key: "amount",
    align: "right",
    render: (_, record) => formatMoney(+record.quantity * +record.unitPrice),
  },
  {
    title: "Picking",
    dataIndex: "pickingStatus",
    key: "pickingStatus",
    align: "right",
    render: (text) => <StatusTag status={text} />,
  },
];

export const LineItemsTable = ({ items, loading }) => {
  return (
    <Table
      size="small"
      pagination={false}
      columns={LineItemsColumns}
      dataSource={items}
      loading={loading}
      rowKey="id"
    />
  );
};
