export const prepareProductFormValues = (values, operation = "create") => {
  if (operation === "update") {
    const toCreate = [];
    const toDelete = [];
    const toUpdate = [];
    values.productVariants.forEach((v, index) => {
      if (v.id && v.isDeleted) {
        toDelete.push({ where: { id: v.id } });
      } else {
        if (v.id) {
          toUpdate.push({
            where: { id: v.id },
            data: {
              name: v.name,
              name_EN: v.name_EN,
              name_ZH: v.name_ZH,
              isDefaultUnit: index === 0,
              convertToDefaultUnit: index !== 0 ? v.convertToDefaultUnit?.toString() : null,
            },
          });
        } else {
          toCreate.push({
            name: v.name,
            name_EN: v.name_EN,
            name_ZH: v.name_ZH,
            isDefaultUnit: index === 0,
            convertToDefaultUnit: index !== 0 ? v.convertToDefaultUnit?.toString() : null,
          });
        }
      }
    });
    return {
      payload: {
        ...values,
        category: { connect: { id: values.category } },
        productVariants: {
          create: toCreate,
        },
      },
      toDelete: toDelete,
      toUpdate: toUpdate,
    };
  } else {
    const variants = values.productVariants.map((v, index) => {
      return {
        name: v.name,
        name_EN: v.name_EN,
        name_ZH: v.name_ZH,
        isDefaultUnit: index === 0,
        convertToDefaultUnit: index !== 0 ? v.convertToDefaultUnit?.toString() : null,
      };
    });
    return {
      payload: {
        ...values,
        category: { connect: { id: values.category } },
        productVariants: {
          create: variants,
        },
      },
    };
  }
};
