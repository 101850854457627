import { Typography } from "antd";
import styled from "styled-components";

const TotalSumBar = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;
const SumLine = styled.div`
  width: 30%;
  display: flex;
  justify-content: space-between;
`;

export const OrderTotalSummary = ({
  total,
  totalWithoutGst,
  gstAmount,
  discountAmount,
}) => {
  return (
    <TotalSumBar>
      <SumLine>
        <Typography.Text>Subtotal</Typography.Text>
        <Typography.Text strong>$ {totalWithoutGst}</Typography.Text>
      </SumLine>
      <SumLine>
        <Typography.Text>GST</Typography.Text>
        <Typography.Text strong>$ {gstAmount}</Typography.Text>
      </SumLine>
      <SumLine>
        <Typography.Text>Discount</Typography.Text>
        <Typography.Text strong>$ {discountAmount}</Typography.Text>
      </SumLine>
      <SumLine>
        <Typography.Text>Total</Typography.Text>
        <Typography.Text strong>$ {total}</Typography.Text>
      </SumLine>
    </TotalSumBar>
  );
};
