import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";

import { Button, Form, notification, PageHeader, Spin } from "antd";
import { CustomerSelection } from "../components/CustomerSelection";

import { SingleCustomerForOrderQuery } from "../graphql/customers.graphql";
import { OrdersQuery, CreateOrderMutation } from "../graphql/orders.graphql";

import { PageContainer } from "../components/atoms/PageContainer";
import { useNavigate } from "react-router-dom";

// import for achiving the function of UnsavedReminder
import UnsavedReminder from "../components/atoms/UnsavedReminder";
import { useCallbackPrompt } from "../hooks/useCallbackPrompt";
import { CreditNoteForm } from "../components/CreditNoteForm";
import { OrderStatusTypes } from "../constants/appConstants";

import { convertToNegativeTotal } from "../utils";

export const NewCreditNote = () => {
  const [customer, setCustomer] = useState({});
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [redirecting, setRedirecting] = useState(false);

  // unsavedReminder
  const [showModal, setShowModal] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(true);
  const [isSaved, setIsSaved] = useState(false);

  const [fetchCustomer, { data }] = useLazyQuery(SingleCustomerForOrderQuery);

  const [createOrder, { loading }] = useMutation(CreateOrderMutation, {
    refetchQueries: [{ query: OrdersQuery }],
  });

  const handleCustomerSelect = (_, option) => {
    fetchCustomer({ variables: { id: option.id } });
  };

  useEffect(() => {
    if (isSaved) {
      setShowModal(false);
    } else setShowModal(showPrompt);
  }, [showPrompt, isSaved]);

  const handleSave = async () => {
    setIsSaved(true);
    const values = form.getFieldsValue();
    const { lineItems: unfilteredItems, totalPrice, orderDate } = values;

    const lineItems = unfilteredItems.reduce((prev, current, currentIndex) => {
      if (current?.name) {
        return [...prev, { ...current, sortingIndex: currentIndex, isOpenItem: true }];
      } else {
        return prev;
      }
    }, []);

    if (lineItems.length === 0) {
      notification.error({ message: "Please add at least 1 item." });
      return;
    }

    const data = {
      lineItems: { create: lineItems },
      total: convertToNegativeTotal(totalPrice.total),
      totalWithoutGst: convertToNegativeTotal(totalPrice.totalWithoutGst),
      orderDate,
      isCreditNote: true,
      status: OrderStatusTypes.CONFIRMED,
      customer: { connect: { id: customer.id } },
    };

    const res = await createOrder({ variables: { order: data } });

    if (res.data) {
      setRedirecting(true);

      notification["success"]({
        message: `Credit Note for ${res.data.createOrder.customer.name} successfully created`,
      });

      navigate("/invoices");
      confirmNavigation();
      setRedirecting(false);
    }
  };

  useEffect(() => {
    if (data) {
      setCustomer(data.customer);
    }
  }, [data]);

  return (
    <>
      <PageHeader
        title={`New Credit Note · ${customer?.name || "select customer"}`}
        extra={[
          <Button loading={loading} type="primary" onClick={handleSave}>
            Save
          </Button>,
        ]}
      />
      <UnsavedReminder
        showModal={showModal}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      {loading || redirecting ? (
        <Spin />
      ) : (
        <PageContainer>
          {customer.id ? (
            <CreditNoteForm
              customer={customer}
              setCustomer={setCustomer}
              form={form}
              loading={loading}
            />
          ) : (
            <CustomerSelection onCustomerSelect={handleCustomerSelect} />
          )}
        </PageContainer>
      )}
    </>
  );
};
